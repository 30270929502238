import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty, Toast, Dialog, Loading, Popup, Collapse, CollapseItem ,Button} from 'vant'

import { setTimeout } from 'core-js';
import { Calendar } from 'vant';
import { Cascader } from 'vant';
import { Uploader } from 'vant';
import areaList from '../../utils/area1'

Vue.use(Button);
Vue.use(Uploader);
Vue.use(Cascader);
Vue.use(Calendar);
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Toast)
export default {
  components: {},
  data () {
    return {
      id:'',
      disable: true,
      length: '0',
      problem: '',
      before: true,
      after: false,
      riqi: '',
      adress: '',
      hosp: '',
      room: '',
      yicard:'',
      doorone:'',
      title:"",
      dateshow:false,
      minDate: new Date(2021, 9, 1),
      maxDate: new Date(2021, 11, 31),
      startTime:'',//开始时间
      endTime:'',//结束时间
      // 地区弹框
      options:[],
      showArea: false,
      fieldValue: '',
      cascaderValue: '',
      fileList: [],
      fileList1:[],
      surefileList:'',
      surefileList1:'',
      isloading:false



    }
  },
  created () {
    this.id= this.$route.query.id
   this.title= this.$route.query.title;
   this.options=areaList.area
   this.begin=this.$route.query.begin;
   this.end=this.$route.query.end
   console.log(this.end)
   this.minDate= new Date(this.begin.split('-')[0], parseInt(this.begin.split('-')[1])-1, this.begin.split('-')[2])
   this.maxDate= new Date(this.end.split('-')[0], parseInt(this.end.split('-')[1])-1, this.end.split('-')[2])

  },
  updated () {
    this.length = this.problem.length

    if (this.fileList1.length<=0||this.fileList.length<=0||this.problem==''||this.yicard==''||this.startTime == '' || this.adress == '' || this.hosp == '' || this.room == '' || this.doorone == '') {
      this.disable = true
    } else {
      this.disable = false
    }
  },
  methods: {
    ...mapActions('followUpAppointment', ['uploadFlies','subApply']),
    // 图片上传
    afterRead(file) {
      // 此时可以自行将文件上传至服务器





    },
    // 其他文件上传
    afterRead1(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      var that = this
      // 此时可以自行将文件上传至服务器
      var formData1 = new FormData()
      if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
          formData1.append("files", file[i].file)
        }
      } else {
        formData1.append("files", file.file)
      }

    },
    onFinish({ selectedOptions }) {
      this.showArea = false;
      this.adress = selectedOptions.map((option) => option.text).join('/');
    },
    showDate(){
      this.dateshow=true

    },
    formatDate(date) {
      console.log(date)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date){
      const [start, end] = date;
      this.dateshow = false;
      var date = `${this.formatDate(start)} -${this.formatDate(end)}`;
      console.log(date)
      this.startTime=this.formatDate(start)//开始时间
      this.endTime=this.formatDate(end)//结束时间

    },

    back () {
      this.$router.go(-1)

    },
    toSub () {
      var that=this
      this.isloading=true
      this.disable=true
      // if(this.startTime==''){
      //   Toast('请选择就医日期')
      // }else if(this.adress==''){
      //   Toast('请输入医院地址')
      // }else if(this.hosp==''){
      //   Toast('请输入请输入所在医院')
      // }else if(this.room==''){
      //   Toast('请输入所在科室')
      // }else if(this.doorone==''){
      //   Toast('请输入意向医生')
      // }else if(this.yicard==''){
      //   Toast('请输入医保卡号')
      // }else if(this.problem==''){
      //   Toast('请输入病情描述')
      // }else if(this.fileList.length<=0){
      //   Toast('请上传就诊记录')
      // }else if(this.fileList1.length<=0){
      //   Toast('请上传其他资料')
      // }
           // 上传就诊记录
      var formData1 = new FormData()
      var list=this.fileList;
      if(list.length>0){
        for(let i = 0; i < list.length; i++){
          formData1.append("files", list[i].file)
        }
      }

      this.uploadFlies(formData1).then(res=>{
        if(res.code==200){
          that.surefileList=res.data
          var formData2 = new FormData()
          var list1=this.fileList1;
          if(list1.length>0){
            for(let i = 0; i < list1.length; i++){
              formData2.append("files", list1[i].file)
            }
          }
          this.uploadFlies(formData2).then(res1=>{
            if(res1.code==200){
              that.surefileList1=res1.data
              var data={
                yhUserEquityId:that.id,// （用户权益数据唯一标识）
                applyHospitalStartDate:that.startTime,//（就医开始日期）
                applyHospitalEndDate:that.endTime,//（就医结束日期）
                applyHospital:that.hosp,// （申请医院）
                address:that.adress,//（医院地址）
                applyDepartment:that.room,//（申请科室）
                doctorName:that.doorone,//（意向医生）
                medicareCard:that.yicard,//（医保卡号）
                illnessNote:that.problem,//（病情描述/诉求描述）
                medicalRecordImage:that.surefileList,//（就诊记录，多图片之间用“，”拼接）
                otherMaterialImage:that.surefileList1//（其他材料，多图片之间用“，”拼接
                              }

              this.subApply(data).then(res2=>{
                this.disable = false
                this.isloading=false
                if(res2.code==200){
                  this.$router.push({ name: 'message', query: { name: this.title, fId: res2.flowId,theid:this.id } })

                }else{
                  Toast(res2.msg)
                }

              })
            }


          })

        }


      })
    }
  }
}







